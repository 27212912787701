<template>
  <v-container>
    <v-card flat class="mx-auto pa-3">
      <div
        class="titleRow"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <div cols="12" class="reftitle">
            {{ data && data.reference_num ? data.reference_num : "" }}
          </div>
          <div class="issueTitle">
            {{ data && data.project_name ? data.project_name : "" }} |
            {{ data && data.account_name ? data.account_name : "" }}
          </div>
          <div class="dateTitle">
            {{
              data && data.end_date
                ? moment(data.end_date).format("MMM-DD-YYYY")
                : ""
            }}
            | {{ data && data.stage ? data.stage : "" }}
          </div>
        </div>
        <div style="display: flex;flex-direction: column" v-if="data && data.stage">
          <img
            style="cursor: pointer;scale: .7;"
            @click="viewIssue()"
            :src="require('../assets/show.svg')"
            alt=""
          />
          <v-img :style="{ width: '30px', height: '30px' }"
          :src="getStatusImage(data&&data.stage?data.stage:null)" />
        </div>
      </div>

      <div class="catSubCatCard">
        <div class="category">
          <div class="catCardTitle">Category</div>
          <div class="catCardContent">
            {{ data && data.category_name ? data.category_name : "" }}
          </div>
        </div>

        <div class="category">
          <div class="catCardTitle">Sub Category</div>
          <div class="catCardContent">
            {{ data && data.sub_category_name ? data.sub_category_name : "" }}
          </div>
        </div>
      </div>

      <div class="nextActionCard">
        <div style="width: 50%;">
          <div class="issueTitle">Next Action</div>
          <div class="nextActionTitle">{{ data && this.data.next_action_name }}
          <!-- <div class="nextActionTitle">{{ this.userNameNextAction ? this.userNameNextAction : (data && this.data.next_action_name) }} -->
            <!-- <v-menu v-if="data && this.data.stage !== 'closed'">
                 <template v-slot:activator="{ props }">
                     <v-icon id="nextaction-menu-activators" size="18px"
                      class="  pl-2 mdi mdi-account-check-outline icon-label" v-bind="props"></v-icon>
                  </template>
                  <v-list  style="overflow: auto;height: auto;max-height: 300px;">
            <div class="pa-1">
          <TextFieldComponent v-if="nextAction.length>5" density="compact" dense @input="Searching" @click.stop :placeholder="search" v-model="searchText"
            append-inner-icon="mdi-magnify" />
        </div>
        <v-list-item  v-for="(item, index) in filteredUsers" :key="index" :value="index">
          <v-list-item-title @click="updateNextAction(item)">{{ item.username }}</v-list-item-title>
        </v-list-item>
      </v-list>
            </v-menu> -->
            <!-- <v-icon id="nextaction-menu-activators" size="18px"
               class="  pl-2 mdi mdi-account-check-outline icon-label"></v-icon> -->
          </div>
          <div class="nextActionDate">
            {{
              data && data.modified
                ? moment(data.modified).format("MMM-DD-YYYY")
                : ""
            }}
          </div>
        </div>

        <div style="display: flex; align-items: center" v-if="data && data.estimate">
          <div class="chip">
            {{ data && data.estimate ? data.estimate : "" }} Minute(s)
          </div>
        </div>
      </div>

      <div v-if="childIssues && childIssues.length > 0">
        <div class="subTaskTitle">Sub Tasks</div>

        <div
          class="subTaskList"
          v-for="(childData, index) in childIssues"
          :key="index"
        >
          <div style="display: flex; gap: 10px">
            <CustomAvatar
              class="avatar"
              v-if="childData && childData.next_action"
              :userId="childData.next_action"
              :size="'medium'"
            />
            <div>
              <div class="nextActionTitle">
                {{
                  childData && childData.next_action_name
                    ? childData.next_action_name
                    : ""
                }}
              </div>
              <div class="nextActionDate">
                {{
                  childData && childData.modified
                    ? moment(childData.modified).format("MMM-DD-YYYY")
                    : ""
                }}
              </div>
            </div>
          </div>

          <div class="nextActionTitle">
            {{ childData && childData.stage ? childData.stage : "" }}
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import config from "../api/config/config.js";
import moment from "moment";
import BaseService from "@/api/service/BaseService";
import CustomAvatar from "./CustomAvatar.vue";
// import TextFieldComponent from "@/components/TextFieldComponent.vue";
export default {
  components: {
    CustomAvatar,
    // TextFieldComponent,
  },
  props: ["message", "data"],
  data() {
    return {
      url: "",
      childIssues: null,
      nextAction: [],
      searchText:'',
      userNameNextAction: ''
    };
  },
  watch:{
     data(){
      if(this.data){
        this.getChildIssue()
      }
     }
  },
  created() {
    if(this.data){
    this.getChildIssue();
    let wsDefault = localStorage.getItem("_WS_DEFAULTS_")
    this.wsDetails = wsDefault ? JSON.parse(wsDefault) : null
    this.nextActionData();
    }
  },
  // computed:{
  //   filteredUsers() {
  //           return this.nextAction.filter(user => {
  //               const username = user.username.toLowerCase();
  //               const searchQuery = this.searchText.toLowerCase();
  //               return username.includes(searchQuery);
  //           });
  //       }
  // },
  methods: {
    moment,
    getStatusImage(status) {
      if(this.data!=undefined && !this.data && !this.data.next_action){
        console.log(status ,'jdjjjj')
        return require("../assets/others.svg");
      }else if (status && (status == "completed" || status == "closed")) {
        return require("../assets/Already.svg");
      } else {
        return require("../assets/inprogress.svg");
      }
    },
    viewIssue() {
      window.open(`${config.webUrl}issueView2?id=${this.data.id}`, "_blank");
    },

    async nextActionData(){
       let res = await BaseService.getNextAction({
            'work_space_id': this.wsDetails.workspace.id,
            'account_id': this.wsDetails.account.id,
            'project_id': this.wsDetails.project.id,
             'active':1 });
       this.nextAction = res? res: [];
    },

    async updateNextAction(item){      
      let actionData = {
        id: this.data.id,
        next_action: item.id
      }
      let res = await BaseService.updateIssue(actionData);
      res && (this.userNameNextAction = item.name)
    },

    async getChildIssue() {
      if(this.data && this.data.id){
      let issueId = this.data.id;
      let res = await BaseService.allIssues({parent_issue_id:issueId});
      this.childIssues = res && res.length > 0 ? res : [];
      }
    },
  },
};
</script>

<style>
.icon-cs {
  display: flex;
  justify-content: center;
}

.reftitle {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.issueTitle {
  color: #000;
  width: 200px;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
}

.dateTitle {
  color: #a50202;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}

.catSubCatCard {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
}

.catCardTitle {
  color: #272727;
  font-size: 12px;
  font-weight: 400;
}

.catCardContent {
  color: #232323;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.nextActionCard {
  border-radius: 4px;
  padding: 8px 12px;
  background: #d7eaf4;
  display: flex;
  justify-content: space-between;
}

.nextActionTitle {
  color: #000;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 700;
}

.nextActionDate {
  color: #000;
  font-size: 10px;
  font-weight: 400;
}

.chip {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  background: #226b92;
  height: 32px;
  padding: 0px 8px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.subTaskTitle {
  margin: 10px 0px;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}
.subTaskList {
  height: 51px;
  border-radius: 4px;
  background: #d7eaf4;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  align-items: center;
  padding: 0px 10px;
}

.avatar {
  cursor: pointer;
}
</style>
