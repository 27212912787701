const apiConfig = {
    // "baseUrl":"http://localhost:3010",
    //"baseUrl":"https://synergy-cf-api.axodesk.com:8081",
 "baseUrl":"https://synergy-cf-api.axodesk.com:8081",            
"baseUrlDocument":"https://synergy-cf-api.axodesk.com:8083",
/*"baseUrlMaster":"https://service1.axodesk.com:8082",       
"baseUrlReport": "https://service1.axodesk.com:8083",      
"baseUrlMasterProject":"https://service1.axodesk.com:8084",   
"baseUrlOthers": "https://service1.axodesk.com:8085",      
"baseUrlIssue":"https://service2.axodesk.com:8082",
"baseUrlMasterWs":"https://service2.axodesk.com:8083",   
"baseUrlBoard":"https://service3.axodesk.com:8082",       
"baseUrlIdentity": "https://service4.axodesk.com:8083",   
"baseUrlSecurity": "https://service4.axodesk.com:8084",    
*/
"baseUrlMaster":"https://synergy-cf-api.axodesk.com.com:8082",
"baseUrlReport": "https://synergy-cf-api.axodesk.com.com:8083",
"baseUrlMasterProject":"https://synergy-cf-api.axodesk.com.com:8084",
"baseUrlOthers": "https://synergy-cf-api.axodesk.com.com:8085",
"baseUrlIssue":"https://synergy-cf-api.axodesk.com:8082",
"baseUrlMasterWs":"https://synergyserver2.coreforce.com:8083",
"baseUrlBoard":"https://synergy-cf-api.axodesk.com.com:8082",
"baseUrlIdentity": "https://synergy-cf-api.axodesk.com:8083",
"baseUrlSecurity": "https://synergy-cf-api.axodesk.com:8084",
"baseUrlCache": "https://synergy-cf-api.axodesk.com:8096",

"gatWayUrl":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
    "apiKey": "apiKey",
    "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281",
    "secrect": "secrect",
    "webUrl":"https://synergy.coreforce.com/"
}
export default apiConfig;
